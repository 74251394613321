<script>
export default {
	hookZone: 'wishlist',
	props: {
		AppBarButtons: Object,
	},
}
</script>

<template>
	<v-btn v-if="$b.td" text class="mr-2" :to="{ name: 'user.projects' }" :color="$vars.basetext">
		<v-icon :color="$vars.basetext" left :size="AppBarButtons.iconSize">mdi-star</v-icon>
		<span class="d-none d-lg-block btn font-1 nowrap">Mis Proyectos</span>
	</v-btn>
</template>
