<script>
import deviceDetect from '../device-detect'
import { get, sync } from 'vuex-pathify'

export default {
	hookZone: 'actions',
	showHook(_, ProductPage) {
		return ProductPage.product.info.customizationKey == 'taopix-default'
	},
	props: {
		ProductPage: Object,
	},
	data() {
		return {
			loading: false,
			waitingLogin: false,
			projectname: '',
			validation: {},
		}
	},
	computed: {
		customizationData() {
			return this.ProductPage.product.info.customizationData
		},
		variantId() {
			return this.ProductPage.selectedVariant.id
		},
		user: get('shop/user'),
		loginDrawer: sync('shop/loginDrawer'),
	},
	watch: {
		user(value) {
			if (this.waitingLogin && value && !value.proUser) {
				this.createProject()
			}
		},
		loginDrawer(value) {
			if (!value) this.waitingLogin = false
		},
	},
	methods: {
		async createProject() {
			if (!this.user) {
				this.waitingLogin = true
				this.loginDrawer = true
				this.$nextTick(() => {
					setTimeout(() => {
						window.google?.accounts?.id?.prompt()
					}, 1000)
				})
				return
			}

			this.loading = true
			let res = await this.$shopApi.post({
				url: `/taopix/create-project/${this.variantId}`,
				data: {
					projectname: this.projectname,
					deviceDetection: deviceDetect(),
				},
				onValidation: ({ validation }) => (this.validation = validation),
			})

			if (res.success) {
				window.location.href = res.data.designurl
			} else {
				this.loading = false
			}
		},
	},
}
</script>

<template>
	<div class="pb-6 pt-2">
		<div class="pb-4">
			<v-text-field
				outlined
				label="Nombre del Proyecto"
				:error-messages="validation.projectname"
				v-model="projectname"
				hide-details="auto"
				counter="60"
			/>
		</div>
		<Button block x-large color="cta" @click="createProject()" :loading="loading"> Crear Ahora </Button>
	</div>
</template>
