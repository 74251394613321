import { views } from '@/v-shop/autoloader'

export default () => [
	{
		path: '/descargar-app',
		name: 'download-app',
		component: views.DownloadApp,
	},
	{
		path: '/profesionales',
		name: 'profesional-registration',
		component: views.ProfessionalRegistration,
	},
]
