export default function deviceDetect() {
	let screenWidth = parseInt(screen.width) / 100
	let screenHeight = parseInt(screen.height) / 7
	let screenAvailableWidth = parseInt(screen.availWidth) / 5
	let screenAvailableHeight = parseInt(screen.availHeight) / 5
	let pixRatio = 1

	if ('deviceXDPI' in screen) {
		pixRatio = screen.deviceXDPI / screen.logicalXDPI
	} else if ('devicePixelRatio' in window) {
		pixRatio = window.devicePixelRatio
	}

	if (isNaN(pixRatio)) {
		pixRatio = 1
	}

	pixRatio = pixRatio * 3

	let detectionString =
		'v1s' +
		screenWidth +
		'o' +
		screenHeight +
		'o' +
		screenAvailableWidth +
		'o' +
		screenAvailableHeight +
		'o' +
		pixRatio +
		'd'

	//Is it a touch
	let touchType = 0
	//Is it a mobile
	let mobileType = 0
	//What is the device
	let deviceType = 0

	if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
		touchType = 1
	}

	if (navigator.userAgent.match(/Mobile| mobile/i)) {
		mobileType = 1
	}

	//Apple
	if (navigator.userAgent.match(/Intel Mac/i)) {
		// iPadOS reports it's an Intel Mac so test for touch support.
		mobileType = touchType
		deviceType = 1
	}

	if (navigator.userAgent.match(/iPad/i)) {
		mobileType = 1
		deviceType = 2
	}

	if (navigator.userAgent.match(/iPhone/i)) {
		mobileType = 1
		deviceType = 3
	}

	if (navigator.userAgent.match(/iPod/i)) {
		mobileType = 1
		deviceType = 4
	}

	//Android
	if (navigator.userAgent.match(/Android/i) && navigator.userAgent.match(/Mobile| mobile/i)) {
		mobileType = 1
		deviceType = 5
	}

	if (navigator.userAgent.match(/Android/i) && !navigator.userAgent.match(/Mobile| mobile/i)) {
		mobileType = 0
		deviceType = 6
	}

	//Windows
	if (navigator.userAgent.match(/(Windows)/i)) {
		mobileType = 0
		deviceType = 7
	}

	if (navigator.userAgent.match(/(Windows Phone OS|Windows CE|Windows Mobile|IEMobile)/i)) {
		mobileType = 1
		deviceType = 8
	}

	//Blackberry
	if (navigator.userAgent.match(/BlackBerry/i)) {
		mobileType = 1
		deviceType = 9
	}

	//Palm
	if (navigator.userAgent.match(/(palm)/i)) {
		mobileType = 1
		deviceType = 10
	}

	//Linux
	if (navigator.userAgent.match(/(Linux|X11)/i)) {
		mobileType = 1
		deviceType = 11
	}

	//WebOS
	if (navigator.userAgent.match(/webOS/i)) {
		mobileType = 1
		deviceType = 12
	}

	//Opera Mini
	if (navigator.userAgent.match(/Opera Mini/i)) {
		mobileType = 1
		deviceType = 13
	}

	let sumCheck =
		screenWidth +
		screenHeight +
		screenAvailableWidth +
		screenAvailableHeight +
		pixRatio +
		touchType +
		mobileType +
		deviceType

	detectionString = detectionString + touchType + 'o' + mobileType + 'o' + deviceType + 'o' + sumCheck
	return detectionString
}
