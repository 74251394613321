import { views } from '@/v-shop/autoloader'

export default (options) => {
	const { store, ssr } = options

	const beforeEnter = (to, from, next) => {
		if (!store.get('shop/user')) {
			let homeRoute = { name: 'home' }
			if (ssr) {
				ssr.responseSent = true
				ssr.res.redirect(301, options.router.resolve(homeRoute).href)
				next(false)
			} else {
				store.set('shop/loginDrawer', true)
			}
		} else {
			next()
		}
	}

	return [
		{
			beforeEnter,
			path: '/user/projects',
			name: 'user.projects',
			component: views.UserProjects,
		},
	]
}
