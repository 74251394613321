<script>
import registration from '../assets/fotografos_01.jpg'

export default {
	data() {
		return {
			name: '',
			lastname: '',
			email: '',
			location: '',
			category: '',
			brandName: '',
			social: '',

			state: 'pending',
			loading: false,
			validation: {},

			registration,
		}
	},
	methods: {
		async submit() {
			return await this.$shopApi.post({
				url: '/user-forms/professionals',
				data: {
					data: {
						name: this.name,
						lastname: this.lastname,
						email: this.email,
						location: this.location,
						category: this.category,
						brandName: this.brandName,
						social: this.social,
					},
				},
				loading: (v) => (this.loading = v),
				onSuccess: () => {
					this.state = 'success'
				},
				onError: () => {
					this.state = 'error'
				},
				onValidation: ({ validation }) => (this.validation = validation),
			})
		},
	},
	cssVars: {
		selector: '.shop-app',
	},
}
</script>

<template>
	<Container class="d-flex align-center py-6 py-md-12">
		<v-row>
			<v-col cols="12" md="6"> <Media :src="registration" :ratio="900 / 600" /></v-col>
			<v-col cols="12" md="6">
				<div>
					<div>
						<form v-if="state != 'success'" @submit.prevent="submit()">
							<div style="max-width: 900px" class="mx-auto">
								<h1 class="pb-3 is-heading font-4">Registro profesionales</h1>
								<v-row>
									<v-col cols="12" md="6">
										<TextField
											outlined
											v-model="name"
											label="Nombre"
											:error-messages="validation.name"
										/>
									</v-col>
									<v-col cols="12" md="6">
										<TextField
											outlined
											v-model="lastname"
											label="Apellido"
											:error-messages="validation.lastname"
										/>
									</v-col>
									<v-col cols="12" md="6">
										<TextField
											outlined
											v-model="email"
											label="Correo electrónico"
											:error-messages="validation.email"
										/>
									</v-col>
									<v-col cols="12" md="6">
										<TextField outlined v-model="location" label="Ubicación" />
									</v-col>
									<v-col cols="12" md="6">
										<TextField outlined v-model="category" label="Rubro" />
									</v-col>
									<v-col cols="12" md="6">
										<TextField
											outlined
											v-model="brandName"
											label="Nombre de la marca o emprendimiento"
										/>
									</v-col>
									<v-col cols="12">
										<TextField
											outlined
											v-model="social"
											label="Red social o página web de referencia"
										/>
									</v-col>
								</v-row>

								<div class="py-3 d-flex justify-end">
									<Button
										:color="$vars.cta"
										:width="$b.m ? '100%' : '20%'"
										type="submit"
										:loading="loading"
									>
										Enviar
									</Button>
								</div>
							</div>
						</form>
						<div v-else class="text-center">
							<div class="font-3 mb-5">Formulario enviado con éxito</div>
							<SafeLink :to="$shop.getShopRoute()">
								<Button :color="$vars.cta">Volver a la tienda</Button>
							</SafeLink>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</Container>
</template>

<style scoped lang="scss"></style>
