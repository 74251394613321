<script>
import application from '../assets/application.png'
import background from '../assets/background.png'
import win from '../assets/win.png'
import mac from '../assets/mac.png'
import bannerMobile from '../assets/banner_mobile.jpg'
import bannerDesktop from '../assets/banner_desk-01.jpg'

export default {
	data() {
		return {
			application,
			background,
			win,
			mac,
			bannerMobile,
			bannerDesktop,

			winDownloadUrl:
				'https://d2eebw31vcx88p.cloudfront.net/tiendadefotolibros/downloads/TDFCli_Win2020r1.exe',
			macDownloadUrl:
				'https://d2eebw31vcx88p.cloudfront.net/tiendadefotolibros/downloads/TDF_MAC_2020R1_v2.dmg',
		}
	},
	computed: {
		backgroundStyles() {
			return {
				background: `url(${this.background})`,
				'background-repeat': 'round',
				'background-size': this.$b.mt ? 'cover' : 'auto',
			}
		},
	},
	trackPageView: true,
	cssVars: {
		selector: '.shop-app',
	},
}
</script>

<template>
	<div class="h100">
		<div class="d-flex" :style="backgroundStyles">
			<v-row class="download-app-container ma-auto text-center">
				<v-col
					cols="12"
					md="6"
					class="d-flex align-center"
					:class="$b.d ? 'justify-end' : 'justify-center'"
				>
					<div>
						<div v-if="$b.td" class="font-14 mb-2">
							Creá y diseñá <br />
							fotolibros, imprimí <br />
							fotos y ¡mucho más!
						</div>
						<div v-else class="font-8">Creá y diseñá fotolibros, imprimí fotos y ¡mucho más!</div>
						<Media v-if="$b.m" :src="application" :aspectRatio="577 / 433" />
						<div class="font-weight-bold font-2">Descargá nuestro editor de escritorio</div>
						<div class="my-6">
							<a download :href="winDownloadUrl" class="download-btn d-flex align-center mx-auto px-4">
								<Media :src="win" width="50px" aspectRatio="1" class="mr-3" />
								<div class="font-weight-bold">Descargar para windows</div>
							</a>
							<a
								download
								:href="macDownloadUrl"
								class="download-btn d-flex align-center mx-auto px-4 my-3"
							>
								<Media :src="mac" width="50px" aspectRatio="1" class="mr-3" />
								<div class="font-weight-bold">Descargar para mac</div>
							</a>
						</div>
						<div class="font-2 font-weight-bold" :class="$b.m ? 'mt-6' : 'mt-16'">
							<div>No necesitas conexión online</div>
							<div>Guardá los proyectos en tu equipo sin limite de tiempo</div>
						</div>
					</div>
				</v-col>
				<v-col cols="12" md="6" v-if="$b.td" :class="$b.mt && 'd-flex justify-center'">
					<Media :src="application" :aspectRatio="577 / 433" max-width="690px" />
				</v-col>
			</v-row>
		</div>
		<div class="pa-4 white">
			<Media
				class="mx-auto"
				:src="$b.m ? bannerMobile : bannerDesktop"
				:ratio="$b.d ? 1161 / 200 : 380 / 200"
			/>
		</div>
	</div>
</template>

<style scoped lang="scss">
.download-app-container {
	max-width: 1600px;
}

.download-btn {
	cursor: pointer;
	background: white;
	max-width: 270px;
	border-radius: 45px;
}
</style>
