<script>
import { get } from 'vuex-pathify'

export default {
	hookZone: 'bottom',
	inject: ['getAppMessageDialog'],
	data() {
		return {
			loadingDialogId: null,
		}
	},
	computed: {
		user: get('shop/user'),
	},
	methods: {
		async processError(taopixCallbackError) {
			this.getAppMessageDialog().open({
				type: 'error',
				title: 'Ups! Lo sentimos, ha ocurrido un error con tu proyecto de Taopix',
				text: taopixCallbackError,
			})
			this.urlCleanup()
		},

		async processCallback(taopixCallback) {
			const { productId, variantId, tpxhash, qty } = JSON.parse(atob(taopixCallback))
			this.addToCart([{ productId, variantId, tpxhash, qty }])
		},

		async processDesktopSid(taopixDesktopSid) {
			this.openLoadingDialog()

			const response = await this.$shopApi.post({
				url: '/taopix-desktop/get-cart-items',
				data: {
					tsid: taopixDesktopSid,
				},
				loading: false,
			})

			if (!response.success) {
				this.closeLoadingDialog()
				return
			}

			const { proUser, addToCartItems } = response.data

			if (proUser && this.user && !this.user.proUser) {
				this.closeLoadingDialog()
				this.getAppMessageDialog().open({
					type: 'error',
					title: 'La cuenta del usuario logueado no coincide con la licencia de tu proyecto de Taopix',
					text: 'Para poder comprar el producto deberás ingresar con una cuenta de Usuario Profesional.',
				})
				this.urlCleanup()
			} else if (proUser && !this.user) {
				this.closeLoadingDialog()
				this.getAppMessageDialog().open({
					type: 'success',
					title: 'Ingresa con tu cuenta de Usuario Profesional',
					htmlText:
						'Para poder comprar el producto deberás ingresar con una cuenta de Usuario Profesional.' +
						'<br><br>' +
						'<b>Utiliza el mismo Email</b> con el cual te registraste en Taopix' +
						(proUser === true ? '.' : ` (${proUser}).`) +
						'<br><br>' +
						'Puedes utilizar cualquier método de registro o ingreso, solo es necesario que sea con el mismo Email.',
					cancel: false,
					accept: 'Ingresar',
					onAccept: () => {
						this.$store.set('shop/loginDrawer', true)
					},
				})
				// watch user
				this.$watch(
					() => this.user,
					(user) => user && window.location.reload()
				)
			} else {
				this.addToCart(addToCartItems)
			}
		},
		async addToCart(items) {
			this.openLoadingDialog()
			await new Promise((resolve) => setTimeout(resolve, 1000))
			for (let { productId, variantId, tpxhash, qty } of items) {
				await this.$shop.addToCart({
					productId,
					variantId,
					customization: { key: 'taopix-default', data: {}, id: tpxhash },
					qty: parseInt(qty),
					sum: false,
					loading: false,
				})
			}

			this.$nextTick(() => {
				this.closeLoadingDialog()
				this.$store.set('shop/cartDrawer', true)
				this.urlCleanup()
			})
		},
		openLoadingDialog() {
			if (this.loadingDialogId) return
			this.loadingDialogId = this.getAppMessageDialog().open({
				type: 'success',
				title: 'Agregando tus Proyectos al Carrito!',
				loading: true,
			})
		},
		closeLoadingDialog() {
			if (this.loadingDialogId) {
				this.getAppMessageDialog().close(this.loadingDialogId)
				this.loadingDialogId = null
			}
		},
		urlCleanup() {
			window.history.replaceState({}, '', this.$router.resolve({ query: {} }).href)
		},
	},
	mounted() {
		const { taopixCallbackError, taopixCallback, taopixDesktopSid } = this.$route.query || {}

		if (taopixCallbackError) {
			this.processError(taopixCallbackError)
		} else if (taopixCallback) {
			this.processCallback(taopixCallback)
		} else if (taopixDesktopSid) {
			this.processDesktopSid(taopixDesktopSid)
		}
	},
}
</script>

<template>
	<div></div>
</template>
