export default (options) => {
	if (options.ssr) return
	const { store } = options
	let _isProUser = !!store.get('shop/user')?.proUser

	store.watch(
		() => store.get('shop/user'),
		(user) => {
			let isProUser = !!user?.proUser
			if (isProUser != _isProUser) {
				window.location.href = String(window.location.href)
			}
		}
	)
}
